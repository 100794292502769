import React from 'react';

export default function Tags() {
  return (
    <div>
        <p>Filtre por tags:</p>
        <ul>
            <li>Estrelas</li>
            <li>Galáxias</li>
            <li>Lua</li>
            <li>Planetas</li>
        </ul>
    </div>
  )
}
